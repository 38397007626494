import { dialogue } from '../../dialogue.js';
import { generateStatueDialogue } from '../../util/generateStatueDialogue.js';
import { shopCellarDialogues } from './shopCellar/dialogues.js';

const elarianDialogues = {
  ...shopCellarDialogues,
  elarianGates: {
    intro: {
      text: `The Great Dragon ruled over the kingdom of Glenfall since it was founded five hundred years ago.
That was until the year 422 AD (Anno Draconis - In the Year of the Dragon) when the Great Dragon was killed by the Dark One,
which ushered in a Dark Age of the kingdom.`,
      options: [
        {
          display: 'Continue',
          next: 'elarianGates.intro2',
        },
        {
          display: 'Skip Intro',
          next: 'elarianGates.intro4',
        },
      ],
      next: 'elarianGates.intro2',
    },
    intro2: {
      text: `It is now the year 460 AD.
The Great King, who sees himself as the true successor of the dragon, maintains limited dominion over Glenfall's capital city - Elarian.
The Dark One's dominion extends throughout the rest of the kingdom.`,
      next: 'elarianGates.intro3',
    },
    intro3: {
      text: `You are Thaddeus, a peasant who has managed to make his way to the capital.
Your aspiration is to defeat the Dark One and finally free the kingdom from his tyranny.
You hope to speak with the Great King and get his blessing to embark on this quest.
After a long journey, you finally arrive at the city gates.`,
      next: 'elarianGates.intro4',
    },
    intro4: {
      text: `Use the arrow keys, wasd, or click any tile to move.
Press "e" or click on the inventory icon in the top-left to open your inventory.`,
    },
  },
  elarianShop: {
    lockedCellar: {
      text: 'The cellar is locked.',
    },
  },
  elarianTower: {
    faint: {
      text: `Everything goes black... You slowly regain consciousness.`,
      nextFunction: () => {
        if (!dialogue.checkDialogue('wizardEldwin.firstFaint')) return 'wizardEldwin.firstFaint';
        else return 'wizardEldwin.faint';
      },
    },
  },
  elarianCourtyard: {
    investigateDragonStatue: {
      text: `The text on the statue reads: "Here lies the great dragon, protector of Glenfall. May his spirit forever watch over us."
      It looks like the statue has a small slit near the base, just big enough to fit your sword...`,
      includeClose: true,
      options: [{ display: 'Put your sword in the slit', next: 'elarianCourtyard.statueActions' }],
    },
    ...generateStatueDialogue('elarianCourtyard'),
  },
};

export { elarianDialogues };
